<template>
  <Main>
    <el-card class="box-card">
      <el-form :inline="true" class="demo-form-inline" size="mini">
        <el-form-item>
          <el-button type="primary">新增</el-button>
        </el-form-item>
        <el-divider direction="vertical"></el-divider>
        <el-form-item>
          <el-input placeholder="请输入KEY" v-model="value"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            @click="$emit('refresh')"
            type="primary"
            icon="el-icon-search"
            circle
          ></el-button>
        </el-form-item>
      </el-form>
      <div>
        <slot></slot>
      </div>
    </el-card>
  </Main>
</template>
<script>
import Main from "@/components/backend/common/main.vue";
export default {
  components: {
    Main,
  },
  data() {
    return {
      value: "",
    };
  },
};
</script>